import { createSlice, Draft } from '@reduxjs/toolkit';
import {
  IAllCompaniesList,
  IClientsCompanies,
  IClientsCompaniesResponse,
  IClientsStatisticResponse,
  IClientsStatistics,
  IMonitoringState,
  IReloadServiceResponse,
  IReloadServiceValues,
} from '../monitoring/monitoring';
import { Dispatch } from 'redux';
import ErrorService from '../../services/ErrorService';
import RestService from '../../services/RestService';
import { AxiosResponse } from 'axios';
import { dispatchNotification } from './notification';
import { dispatch } from '../store';
import { NotificationType } from '../notification/notification.type';
import { downloadPdfHelper } from '../../helpers/downloadPdf.helper';
import { getReadableDate } from '../../helpers/getReadableDate';

const initialState: IMonitoringState = {
  loading: false,
  clientStatistics: null,
  clientsCompanies: null,
  allCompaniesList: null,
};

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    loading: (state: Draft<IMonitoringState>, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setClientStatistic: (
      state: Draft<IMonitoringState>,
      { payload }: { payload: IClientsStatistics[] }
    ) => {
      state.clientStatistics = payload;
    },

    setClientsCompanies: (
      state: Draft<IMonitoringState>,
      { payload }: { payload: IClientsCompanies[] }
    ) => {
      state.clientsCompanies = payload;
    },

    setAllCompaniesList: (
      state: Draft<IMonitoringState>,
      { payload }: { payload: IAllCompaniesList[] }
    ) => {
      state.allCompaniesList = payload;
    },

    reset: (state) => Object.assign(state, initialState),
  },
});

const { loading, setAllCompaniesList, setClientStatistic, setClientsCompanies, reset } =
  monitoringSlice.actions;

export const getClientsStatisticAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    const result: AxiosResponse<IClientsStatisticResponse> =
      await RestService.getClientsStatistic();
    dispatch(loading(false));
    dispatch(setClientStatistic(result.data.data));
  } catch (e) {
    dispatch(loading(false));
    await ErrorService.handleErrorMessage(e);
  }
};

export const getClientsCompaniesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    const result: AxiosResponse<IClientsCompaniesResponse> =
      await RestService.getClientsCompanies();
    dispatch(loading(false));
    dispatch(setClientsCompanies(result.data.data));
  } catch (e) {
    dispatch(loading(false));
    await ErrorService.handleErrorMessage(e);
  }
};

export const getCompaniesListAction = () => async (dispatch: Dispatch) => {
  const result: AxiosResponse<IResponse<IAllCompaniesList[]>> =
    await RestService.getCompaniesList();

  dispatch(setAllCompaniesList(result.data.data));
};

export const reloadServicesAction = (data: IReloadServiceValues, companyId: string) => async () => {
  try {
    const result: AxiosResponse<IResponse<IReloadServiceResponse>> =
      await RestService.reloadServices({ updateAPIs: data }, companyId);

    const errors = result.data.data?.errors;

    dispatchNotification({
      open: true,
      message: errors?.length ? errors?.join(', ') : 'Data reloaded',
      type: errors?.length ? NotificationType.Error : NotificationType.Success,
    })(dispatch);
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
  }
};

export const resetMonitoringDataAction = () => (dispatch: Dispatch) => {
  dispatch(reset());
};

export const downloadPdfAction =
  (clientId: string, companyId: string, companyName: string) => async () => {
    try {
      const result = await RestService.downloadPdf(clientId, companyId);
      downloadPdfHelper(result.data, `Report ${companyName}: ${getReadableDate(new Date())}`);
    } catch (e) {
      await ErrorService.handleErrorMessage(e);
    }
  };

export default monitoringSlice.reducer;
