import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { INotificationState } from '../redux/notification/notification.type';
import { useDispatch, useSelector } from '../redux/store';
import { dispatchNotification } from '../redux/slices/notification';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const CAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Notifications = () => {
  const { notification } = useSelector<INotificationState>((state) => state.notification);
  const dispatch = useDispatch();

  if (!notification?.open) {
    return null;
  }

  return (
    <>
      <Snackbar
        open={notification?.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        TransitionComponent={TransitionLeft}
        onClose={() => {
          dispatch(dispatchNotification({ open: false, message: undefined }));
        }}
      >
        <CAlert
          severity={notification?.type ? notification.type : 'error'}
          sx={{ width: '100%', minWidth: '200px' }}
          variant="outlined"
        >
          {notification?.message}
        </CAlert>
      </Snackbar>
    </>
  );
};

export default Notifications;
