export enum SessionStorageField {
  Session = 'Session',
  SelectedPortfolio = 'SelectedPortfolio',
  SelectedStakeholderMatches = 'SelectedStakeholderMatches',
}

class SessionStorageService {
  public static clear() {
    sessionStorage.clear();
  }

  public static getItem<T>(field: SessionStorageField, parse: boolean = true): Maybe<T> {
    const value = sessionStorage.getItem(field);

    return parse ? value && JSON.parse(value) : value;
  }

  public static setItem<T>(field: SessionStorageField, value: T) {
    sessionStorage.setItem(field, JSON.stringify(value));
  }

  public static removeItem(field: SessionStorageField) {
    sessionStorage.removeItem(field);
  }
}

export default SessionStorageService;
