import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import Iconify from '../../../components/Iconify';

interface IFormValuesProps {
  password: string;
}

const UpdatePasswordSchema = Yup.object().shape({
  email: Yup.string().required('Field is required'),
});

const UpdatePasswordForm: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const methods = useForm<IFormValuesProps>({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues: { password: '' },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {};

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          mode={'white'}
          placeholder="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Update Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default UpdatePasswordForm;
