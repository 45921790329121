import { createSlice, Draft } from '@reduxjs/toolkit';
import { IClient, IClientsResponse, IClientsState, IUser } from '../clients/clients.type';
import { Dispatch } from 'redux';
import RestService from '../../services/RestService';
import { AxiosResponse } from 'axios';
import ErrorService from '../../services/ErrorService';
import { notification } from './notification';

const initialState: IClientsState = {
  loading: false,
  clients: null,
  client: null,
  invitedLink: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    loading: (state: Draft<IClientsState>, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    getClients: (state: Draft<IClientsState>, { payload }: { payload: Maybe<IClient[]> }) => {
      state.clients = payload;
    },
    setInvitedLink: (state: Draft<IClientsState>, { payload }: { payload: Maybe<string> }) => {
      state.invitedLink = payload;
    },
    getClientById: (state: Draft<IClientsState>, { payload }: { payload: Maybe<IClient> }) => {
      state.client = payload;
    },
    reset: (state) => Object.assign(state, initialState),
  },
});

export const { loading, getClients, getClientById, setInvitedLink } = clientsSlice.actions;

export default clientsSlice.reducer;

export const getClientsAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    const response: AxiosResponse<IClientsResponse> = await RestService.getClients();
    dispatch(getClients(response.data.data));
    dispatch(loading(false));
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
    dispatch(loading(false));
  }
};

export const inviteUserAction = (user: IUser, clientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    await RestService.inviteUser(user, clientId);

    dispatch(loading(false));

    dispatch(
      notification({
        type: 'success',
        open: true,
        message: `Invite link has been sent`,
      })
    );
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
    dispatch(loading(false));
    throw e;
  }
};

export const resetInvitedLinkAction = () => (dispatch: Dispatch) => {
  dispatch(setInvitedLink(null));
};

export const createClientAction = (client: Partial<IClient>) => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));

    await RestService.createClient(client);

    dispatch(loading(false));

    dispatch(
      notification({
        type: 'success',
        open: true,
        message: `New Client has been created`,
      })
    );
  } catch (e) {
    dispatch(loading(false));
    await ErrorService.handleErrorMessage(e);
  }
};

export const getClientByIdAction = (clientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));

    const result = await RestService.getClientById(clientId);

    dispatch(getClientById(result.data.data));

    dispatch(loading(false));
  } catch (e) {
    dispatch(loading(false));
    await ErrorService.handleErrorMessage(e);
  }
};

export const resetClientDataAction = () => (dispatch: Dispatch) => {
  dispatch(getClientById(null));
};
