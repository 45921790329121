// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { InputLabel, Select, SelectProps } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  children: any;
}

export default function RHFSelect({ name, children, label, ...other }: IProps & SelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputLabel id={name} error={!!error}>
            {label}
          </InputLabel>
          <Select
            {...field}
            id={name}
            labelId={name}
            label={label}
            fullWidth
            error={!!error}
            {...other}
          >
            {children}
          </Select>
        </>
      )}
    />
  );
}
