export interface INotificationState {
  loading?: boolean;
  error?: Error;
  notification?: NotificationPayload;
}
export interface NotificationPayload {
  open: boolean;
  message?: string;
  type?: NotificationType;
}

export enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error',
}
