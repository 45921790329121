import { API_URLS } from '../../api';
import axios from '../../utils/axios';
import { IClient, IUser } from '../../redux/clients/clients.type';
import { IMappedList } from '../../pages/dashboard/stakeholderMatching/SubmittedMatchingList/SubmittedMatchingList';
import { IReloadServiceValues } from '../../redux/monitoring/monitoring';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export enum Entry {
  Alerts = 'alerts',
  Cases = 'cases',
}

export default class RestService {
  public static login(email: string, password: string) {
    return axios.post(API_URLS.LOGIN, {
      email,
      password,
      // for BE checking role
      main: 'no',
    });
  }

  public static logout(refreshToken: string) {
    return axios.post(API_URLS.LOGOUT, {
      refresh: refreshToken,
    });
  }

  public static refreshToken(token: string) {
    return axios.post(API_URLS.REFRESH_TOKEN, { refresh: token });
  }

  public static getClients() {
    return axios.get(API_URLS.GET_CLIENTS);
  }

  public static inviteUser(client: IUser, clientId: string) {
    return axios.post(API_URLS.INVITE_USER, { ...client, clientID: clientId });
  }

  public static createClient(client: Partial<IClient>) {
    return axios.post(API_URLS.CREATE_CLIENT, client);
  }

  public static getClientById(clientId: string) {
    return axios.get(API_URLS.GET_CLIENT_BY_ID(clientId));
  }

  public static getUsers() {
    return axios.get(API_URLS.GET_USERS);
  }

  public static getMatches(companyId: string) {
    return axios.get(API_URLS.GET_MATCHES(companyId));
  }

  public static updateMatches(list: { reviews: Partial<IMappedList>[] }) {
    return axios.put(API_URLS.UPDATE_MATCHES, list);
  }

  public static getCompanies(companyId?: string) {
    const config = companyId && companyId?.length ? { params: { filter: companyId } } : {};
    return axios.get(API_URLS.GET_COMPANIES, config);
  }

  public static getRelatedInfoLogs() {
    return axios.get(API_URLS.GET_RELATED_INFO_LOGS);
  }

  public static getClientsStatistic() {
    return axios.get(API_URLS.GET_CLIENTS_STATISTIC);
  }

  public static getClientsCompanies() {
    return axios.get(API_URLS.GET_CLIENTS_COMPANIES);
  }

  public static getCompaniesList() {
    return axios.get(API_URLS.GET_ALL_COMPANIES_LIST);
  }

  public static reloadServices(data: { updateAPIs: IReloadServiceValues }, companyId: string) {
    return axios.put(API_URLS.RELOAD_SERVICES(companyId), data);
  }

  public static downloadPdf(clientId: string, companyId: string) {
    return axios.get(API_URLS.DOWNLOAD_PDF(clientId, companyId), { responseType: 'blob' });
  }
}
