const path = (root: string, sublink: string) => `${root}${sublink}`;

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  updatePassword: path(ROOTS_AUTH, '/reset-password/:token'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    clients: path(ROOTS_DASHBOARD, '/clients'),
    client: path(ROOTS_DASHBOARD, '/clients/create'),
    inviteUser: path(ROOTS_DASHBOARD, '/clients/invite'),
    clientAccount: path(ROOTS_DASHBOARD, '/clients/account'),
    users: path(ROOTS_DASHBOARD, '/users'),
    logs: {
      relatedInfo: path(ROOTS_DASHBOARD, '/logs/related'),
    },
    settings: {
      root: path(ROOTS_DASHBOARD, '/settings'),
      restrictions: path(ROOTS_DASHBOARD, '/settings/restrictions'),
    },
  },
  dataAnalysis: {
    stakeholdersMatching: path(ROOTS_DASHBOARD, '/stakeholders-matching'),
  },
  monitoring: {
    clientStatistics: path(ROOTS_DASHBOARD, '/client-statistics'),
    clientCompanies: path(ROOTS_DASHBOARD, '/client-companies'),
    allCompaniesList: path(ROOTS_DASHBOARD, '/all-companies'),
  },
};
