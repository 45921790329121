import { createSlice } from '@reduxjs/toolkit';
import { ILogsState, IRelatedInfoLog, IRelatedInfoLogResponse } from '../logs/logs.type';
import { Dispatch } from 'redux';
import RestService from '../../services/RestService';
import { AxiosResponse } from 'axios';
import ErrorService from '../../services/ErrorService';

const initialState: ILogsState = {
  loading: false,
  userRelatedInfoLog: null,
};

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    loading: (state: ILogsState, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    getUserRelatedInfoLog: (
      state: ILogsState,
      { payload }: { payload: Maybe<IRelatedInfoLog[]> }
    ) => {
      state.userRelatedInfoLog = payload;
    },
    reset: (state) => Object.assign(state, initialState),
  },
});

export default logsSlice.reducer;

const { loading, getUserRelatedInfoLog, reset } = logsSlice.actions;

export const getUserRelatedInfoLogAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));

    const result: AxiosResponse<IRelatedInfoLogResponse> = await RestService.getRelatedInfoLogs();

    dispatch(getUserRelatedInfoLog(result.data.data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(loading(false));
    await ErrorService.handleErrorMessage(e);
  }
};

export const resetLogsDataAction = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
