import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
// assets
import { SentIcon } from '../../../assets';
import ResetPasswordForm from '../../../sections/auth/reset-password';
import { PATH_AUTH } from '../../../routes/path';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ResetPassword = () => {
  const theme = useTheme();
  const [email, setEmail] = React.useState<string>('');

  const [sent, setSent] = React.useState<boolean>(false);

  const handleSendEmail = React.useCallback((value: string) => {
    setEmail(value);
  }, []);

  return (
    <Page title="Reset Password" sx={{ height: 1, background: 'rgb(40, 39, 39)' }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 350, mx: 'auto' }}>
            {!sent ? (
              <>
                <Typography
                  variant="h6"
                  paragraph
                  align={'center'}
                  color={theme.palette.common.white}
                >
                  Forgot your password?
                </Typography>
                <div>
                  <ResetPasswordForm
                    onSent={() => setSent(true)}
                    onGetEmail={(value: string) => handleSendEmail(value)}
                  />
                </div>
                <Button
                  fullWidth
                  size="large"
                  component={RouterLink}
                  to={PATH_AUTH.login}
                  sx={{ mt: 1 }}
                >
                  Back
                </Button>
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                <Typography variant="h4" gutterBottom color={theme.palette.common.white}>
                  Request sent successfully
                </Typography>

                <Typography color={theme.palette.common.white}>
                  We have sent a confirmation email to &nbsp;
                  <strong>{email}</strong>
                  <br />
                  Please check your email.
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to={PATH_AUTH.login}
                  sx={{ mt: 5 }}
                >
                  Back
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default ResetPassword;
