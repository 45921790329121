import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { NotificationPayload, INotificationState } from '../notification/notification.type';

// initial state
export const initialState: INotificationState = {
  loading: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notification: (state, { payload }) => {
      state.notification = payload;
    },
    resetStore: (state) => {
      state.loading = false;
      state.error = undefined;
      state.notification = undefined;
    },
  },
});

export const { notification, resetStore } = notificationSlice.actions;

export default notificationSlice.reducer;

export const dispatchNotification = (v: NotificationPayload) => (dispatch: Dispatch) => {
  dispatch(notification(v));
};

export const resetNotificationStoreAction = () => async (dispatch: Dispatch) => {
  dispatch(resetStore());
};
