import { createSlice, Draft } from '@reduxjs/toolkit';
import { IUser } from '../clients/clients.type';
import { Dispatch } from 'redux';
import RestService from '../../services/RestService';
import { AxiosResponse } from 'axios';
import ErrorService from '../../services/ErrorService';
import { IUsersResponse, IUsersState } from '../clients/users.type';

const initialState: IUsersState = {
  loading: false,
  users: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loading: (state: Draft<IUsersState>, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    getUsers: (state: Draft<IUsersState>, { payload }: { payload: Maybe<IUser[]> }) => {
      state.users = payload;
    },
    reset: (state) => Object.assign(state, initialState),
  },
});

export const { loading, getUsers, reset } = usersSlice.actions;

export default usersSlice.reducer;

export const getUsersAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    const response: AxiosResponse<IUsersResponse> = await RestService.getUsers();
    dispatch(getUsers(response.data.data));
    dispatch(loading(false));
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
    dispatch(loading(false));
  }
};

export const resetMatchesDataAction = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
