import { createContext, ReactNode, useEffect } from 'react';
// utils
import { isValidToken } from '../utils/jwt';
// @types
import RestService from '../services/RestService';
import { AxiosResponse } from 'axios';
import TokenService from '../services/TokenService';
import ErrorService from '../services/ErrorService';
import { dispatch, useSelector } from '../redux/store';
import {
  handleInitializeAction,
  handleLoginAction,
  handleLogoutAction,
} from '../redux/slices/auth';
import { AuthUser, JWTContextType } from '../redux/auth/auth.type';

// ----------------------------------------------------------------------

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

export interface ILoginResponse {
  expiresAt?: number;
  refresh?: string;
  token?: string;
  data: AuthUser;
}

function AuthProvider({ children }: AuthProviderProps) {
  const { isInitialized, isAuthenticated, user } = useSelector((state) => state.auth);
  useEffect(() => {
    const tokenData = TokenService.getLocalTokenData();
    const initialize = async () => {
      try {
        if (tokenData && tokenData.token && tokenData.data && isValidToken(tokenData?.expiresAt)) {
          TokenService.setLocalTokenData(tokenData);

          const parsedUser = TokenService.getUserDataFromToken(tokenData.token);
          const user: AuthUser = {
            ...parsedUser,
            ...tokenData.data,
          };

          dispatch(handleInitializeAction(true, user));
        } else {
          dispatch(handleInitializeAction(false, null));
        }
      } catch (err) {
        dispatch(handleInitializeAction(false, null));
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response: AxiosResponse<ILoginResponse> = await RestService.login(email, password);
      const { token } = response.data;
      TokenService.setLocalTokenData(response.data);

      if (token) {
        const parsedUser = TokenService.getUserDataFromToken(token);
        const user: AuthUser = {
          ...parsedUser,
          ...response.data.data,
        };
        handleLoginAction(user)(dispatch);
      }
    } catch (e) {
      await ErrorService.handleErrorMessage(e);
    }
  };

  const logout = async () => {
    const refreshToken = TokenService.getLocalRefreshToken();
    try {
      await RestService.logout(refreshToken || '');
    } catch (e) {
    } finally {
      handleLogoutAction()(dispatch);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitialized,
        user,
        method: 'jwt',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
