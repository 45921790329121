import axios, { AxiosInstance, AxiosResponse } from 'axios';
// config
import { HOST_API } from '../config';
import TokenService from '../services/TokenService';
import RestService from '../services/RestService';
import { dispatch } from '../redux/store';
import { ILoginResponse } from '../contexts/JWTContext';
import { handleLogoutAction } from '../redux/slices/auth';

// ----------------------------------------------------------------------

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.defaults.baseURL = HOST_API;
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstance.interceptors.request.use(
  // @ts-ignore
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      const bToken = `Bearer ${token}`;
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: bToken,
        },
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    const refreshToken = TokenService.getLocalRefreshToken();

    if (
      err.response.status === 401 &&
      !originalConfig._retry &&
      refreshToken &&
      err.response?.data?.path !== '/refresh'
    ) {
      originalConfig._retry = true;

      try {
        if (refreshToken) {
          const response: AxiosResponse<ILoginResponse> = await RestService.refreshToken(
            refreshToken
          );

          TokenService.updateLocalAccessToken(response.data);
        }
        return await axiosInstance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    if (err.response.status === 401) {
      await handleLogoutAction()(dispatch);
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
