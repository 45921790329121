export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum UserRole {
  Investigator = 'CLIENT_INVESTIGATOR',
  ClientTrial = 'CLIENT_TRIAL',
  Admin = 'CLIENT_ADMIN',
  ClientDemo = 'KYP_DEMO',
  KYP = 'KYP',
}

export interface ParsedAuthUser {
  cid: string; // clientId
  rls: UserRole[];
  exp: number;
}

export interface AuthUser extends ParsedAuthUser {
  _id: string; // userId
  firstName: string;
  lastName: string;
  photoURL?: string;
  email?: string;
}

export type IAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: Maybe<AuthUser>;
};

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: Maybe<AuthUser>;
  method: 'jwt';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};
