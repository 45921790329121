import React from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack } from '@mui/material';

import Page from '../../../components/Page';
// sections
import { LoginForm } from '../../../sections/auth/login';
import SessionStorageService from '../../../services/SessionStorageService';
import Logo, { LogoTheme } from '../../../components/Logo';
import useSettings from '../../../hooks/useSettings';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {},
}));

// ----------------------------------------------------------------------

export const Login: React.FC = () => {
  const { themeMode } = useSettings();
  React.useEffect(() => {
    SessionStorageService.clear();
  }, []);

  return (
    <Page title="Login">
      <RootStyle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Card sx={{ padding: 2, maxWidth: '400px' }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Logo theme={themeMode === 'light' ? LogoTheme.Black : LogoTheme.White} />
              </Box>
            </Stack>
            <LoginForm />
          </Card>
        </Box>
      </RootStyle>
    </Page>
  );
};

export default Login;
