import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export enum LogoTheme {
  White = 'white',
  Black = 'black',
}

interface ILogoProps extends BoxProps {
  disabledLink?: boolean;
  style?: Record<any, string>;
  theme?: LogoTheme;
}

const Logo = (props: ILogoProps) => {
  const { disabledLink = false, theme = LogoTheme.Black, style = {}, sx } = props;

  const logo = React.useMemo(() => {
    switch (theme) {
      case LogoTheme.Black: {
        return (
          <Box sx={{ width: 100, height: 40, ...sx }}>
            <svg
              id="a"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 339.349854 225.726624"
            >
              <polygon
                points="21.333496 134.09436 42.902161 134.09436 67.444214 184.514648 92.62146 184.514648 60.340149 122.078552 90.318481 62.464172 66.813782 62.464172 41.450745 112.838562 21.333496 112.838562 21.333496 .098633 .171753 .097473 0 114.345215 0 184.514648 21.333496 184.514648 21.333496 134.09436"
                fillRule="evenodd"
              />
              <path
                d="M177.569519,62.606445l1.013855,71.917236c0,16.087708-12.973206,29.176208-28.9198,29.176208s-28.9198-12.994385-28.9198-28.959778l.254028-72.253601h-21.436951l.159973,72.247742c0,22.459045,15.172668,42.290527,36.898987,48.225525,8.33197,2.275879,17.696716,2.286438,26.094604-.001221,2.825134-.770386,5.695007-1.845398,8.527222-3.195679l8.971863-4.27771-4.309448,8.956543c-5.923218,12.308716-18.444824,20.261963-31.897888,20.261963-3.006287,0-6.012634-.392822-8.931885-1.165588l-1.895996-.501038v21.651062l1.251465,.21759c3.140381,.544556,6.363098,.820923,9.576416,.820923,30.658142,0,55.602478-25.265381,55.602478-56.325806l-.324585-106.903748-21.714539,.109375Z"
                fillRule="evenodd"
              />
              <path
                d="M267.764709,123.145996c-.025024,.260559-.078674,.512451-.078674,.779541v23.827209c0,4.573975,3.707886,8.281555,8.281555,8.281555h.343994c4.573669,0,8.281555-3.707581,8.281555-8.281555v-23.827209c0-.26709-.05365-.518982-.078064-.779541,4.748291-2.856812,7.957764-8.009094,7.957764-13.954651,0-9.020569-7.312683-16.333008-16.333557-16.333008-9.020264,0-16.333008,7.312439-16.333008,16.333008,0,5.945557,3.209534,11.097839,7.958435,13.954651Z"
                fill="#6c0"
                fillRule="evenodd"
              />
              <path
                d="M199.497925,52.901978c0-15.169128,9.539978-28.010559,23.708191-31.946045,3.108643-.818665,6.281921-1.244385,9.394104-1.265564,18.247192,.155212,33.680969,15.685425,33.702087,33.913818l.001343,.918579h-2.448181v9.195068c-28.784851,5.6427-50.528076,30.398804-50.528076,59.999329v101.612061l20.887695,.219971v-55.814209l5.702087,3.936646c11.234863,7.756897,22.849548,11.52887,35.507568,11.52887,35.24762,0,63.92511-27.581299,63.92511-61.483337,0-30.005554-22.347168-55.019592-51.718445-60.206482v-8.987915h-2.469116l-.014099-.958557c-.432861-29.534973-23.858704-53.564209-52.218628-53.564209h-2.632324c-29.080872,0-52.740845,23.731689-52.740845,52.901978v2.509949h21.941528v-2.509949Zm76.840698,29.967773c22.823669,0,40.702698,17.942566,40.702698,40.847412s-17.879028,40.846191-40.702698,40.846191c-22.944824,0-40.916748-17.941406-40.916748-40.846191s17.971924-40.847412,40.916748-40.847412Z"
                fillRule="evenodd"
              />
            </svg>
          </Box>
        );
      }
      case LogoTheme.White: {
        return (
          <Box sx={{ width: 100, height: 40, ...sx }}>
            <svg
              id="a"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 209.474898 139.337224"
            >
              <polygon
                points="37.246936 75.357034 55.75206 38.55806 41.24302 38.55806 25.586849 69.65332 13.168783 69.65332 13.168783 .060896 .106001 .06017 0 70.583368 0 113.89776 13.168783 113.89776 13.168783 82.774185 26.482773 82.774185 41.632174 113.89776 57.173632 113.89776 37.246936 75.357034"
                fill="#fff"
              />
              <path
                d="M123.014645,38.578389l-13.404018,.067521,.62584,44.393277c0,9.930677-8.008142,18.00997-17.851695,18.00997s-17.851695-8.021211-17.851695-17.87638l.156823-44.600922h-13.232674l.09874,44.597292c0,13.863597,9.365823,26.105236,22.777102,29.768798,5.143216,1.404874,10.92389,1.411408,16.107764-.000726,1.743931-.475551,3.515451-1.139145,5.263738-1.972631l5.538178-2.640581-2.660184,5.52874c-3.656301,7.597933-11.385647,12.507367-19.69001,12.507367-1.85574,0-3.71148-.242495-5.513493-.719499l-1.170365-.30929v13.364812l.772499,.134316c1.938508,.336153,3.927838,.506771,5.911359,.506771,18.924771,0,34.322475-15.595911,34.322475-34.768986l-.200385-65.989849Z"
                fill="#fff"
              />
              <path
                d="M180.538526,67.401959c0-5.568252-4.514003-10.082071-10.082439-10.082071-5.568068,0-10.082071,4.513819-10.082071,10.082071,0,3.670089,1.981156,6.850501,4.91259,8.613962-.015458,.160833-.048581,.31633-.048581,.481211v14.708142c0,2.823414,2.288837,5.112067,5.112067,5.112067h.212359c2.82323,0,5.112067-2.288653,5.112067-5.112067v-14.708142c0-.164882-.033124-.320378-.048213-.481211,2.931066-1.763461,4.912222-4.943873,4.912222-8.613962Z"
                fill="#6c0"
                fillRule="evenodd"
              />
              <path
                d="M177.549965,39.204048v-5.548071h-1.524124l-.008712-.591717c-.26718-18.23141-14.727576-33.064261-32.233679-33.064261h-1.624861c-17.951161,0-32.556038,14.649164-32.556038,32.655504v1.549354h13.544142v-1.549354c0-9.363645,5.888852-17.290472,14.634644-19.719777,1.918905-.505319,3.877741-.768143,5.798824-.781211,11.263673,.095836,20.790676,9.682374,20.803744,20.93443l.000817,.567032h-1.511237v5.675943c-17.768383,3.483142-31.190098,18.764681-31.190098,37.036568v62.723427l12.893617,.135768v-34.453161l3.519807,2.430032c6.935065,4.788186,14.10464,7.116574,21.918205,7.116574,21.757752,0,39.459884-17.02547,39.459884-37.95264,0-18.521914-13.794533-33.962635-31.924934-37.16444Zm-6.970823,62.378108c-14.163448,0-25.25723-11.074905-25.25723-25.213668s11.093781-25.214394,25.25723-25.214394c14.088667,0,25.125092,11.075631,25.125092,25.214394s-11.036425,25.213668-25.125092,25.213668Z"
                fill="#fff"
                fillRule="evenodd"
              />
            </svg>
          </Box>
        );
      }
    }
  }, [sx, theme]);

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink style={style} to="/">
      {logo}
    </RouterLink>
  );
};

export default Logo;
