import styled from 'styled-components';

interface ILayoutProps {
  spacing: string;
}

export const Layout = styled.div<ILayoutProps>`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ spacing }) => spacing};
`;
