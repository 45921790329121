import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slices/auth';
import notificationReducer from './slices/notification';
import clientsReducer from './slices/clients';
import usersReducer from './slices/users';
import stakeholdersMatchingReducer from './slices/stakeholdersMatching';
import logsReducer from './slices/logs';
import monitoringReducer from './slices/monitoring';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  clients: clientsReducer,
  users: usersReducer,
  stakeholdersMatching: stakeholdersMatchingReducer,
  logs: logsReducer,
  monitoring: monitoringReducer,
});

export { rootPersistConfig, rootReducer };
