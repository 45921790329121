import { API } from '../config';

export const API_URLS = {
  LOGIN: `${API.login}`,

  LOGOUT: `${API.logout}`,

  REFRESH_TOKEN: `${API.refresh}`,

  GET_CLIENTS: `${API.getClients}`,

  INVITE_USER: `${API.inviteUser}`,

  CREATE_CLIENT: `${API.createClient}`,

  GET_CLIENT_BY_ID: (clientId: string) => `${API.createClient}/${clientId}`,

  GET_USERS: `${API.getUsers}`,

  GET_MATCHES: (companyId: string) => `${API.getMatches}/${companyId}`,

  GET_COMPANIES: `${API.getCompanies}`,

  UPDATE_MATCHES: `${API.updateMatches}`,

  GET_RELATED_INFO_LOGS: `${API.relatedInfoLogs}`,

  GET_CLIENTS_STATISTIC: `${API.clientsStatistic}`,

  GET_CLIENTS_COMPANIES: `${API.clientsCompanies}`,

  GET_ALL_COMPANIES_LIST: `${API.allCompaniesList}`,

  RELOAD_SERVICES: (companyId: string) => `${API.reloadService}/${companyId}`,

  DOWNLOAD_PDF: (clientId: string, companyId: string) =>
    `${API.downloadPdf}/${clientId}/company/${companyId}`,
};
