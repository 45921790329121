import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TextFieldStyle = styled(TextField)(({ classes, theme }) => ({
  '& .MuiOutlinedInput-root': {
    legend: {
      //marginLeft: '30px',
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: '20px !important',
  },
  '& .MuiInputLabel-shrink': {
    marginLeft: '0px',
    paddingLeft: '3px',
    paddingRight: 0,
    background: theme.palette.background,
  },
  ...classes,
}));
export default TextFieldStyle;
