import SessionStorageService, {
  SessionStorageField,
} from '../SessionStorageService/sessionStorage.service';
import jwt_decode from 'jwt-decode';
import { ParsedAuthUser } from '../../redux/auth/auth.type';
import { ILoginResponse } from '../../contexts/JWTContext';

export default class TokenService {
  public static setLocalTokenData(session: ILoginResponse) {
    SessionStorageService.setItem<ILoginResponse>(SessionStorageField.Session, session);
  }

  public static getLocalTokenData() {
    return SessionStorageService.getItem<ILoginResponse>(SessionStorageField.Session);
  }

  public static getLocalRefreshToken() {
    const localData = SessionStorageService.getItem<ILoginResponse>(SessionStorageField.Session);
    return localData?.refresh;
  }

  public static getLocalAccessToken() {
    const localData = SessionStorageService.getItem<ILoginResponse>(SessionStorageField.Session);
    return localData?.token;
  }

  public static updateLocalAccessToken(session: ILoginResponse) {
    if (session) {
      SessionStorageService.setItem<ILoginResponse>(SessionStorageField.Session, session);
    }
  }

  public static getUserDataFromToken(token: string): ParsedAuthUser {
    return jwt_decode(token);
  }

  public static clearDataFromToken() {
    SessionStorageService.removeItem(SessionStorageField.Session);
  }
}
