import { IAuthState, AuthUser } from '../auth/auth.type';
import { createSlice, Draft } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import TokenService from '../../services/TokenService';
import SessionStorageService from '../../services/SessionStorageService';
import { resetClientDataAction } from './clients';
import { resetLogsDataAction } from './logs';
import { resetMonitoringDataAction } from './monitoring';
import { resetNotificationStoreAction } from './notification';
import { resetMatchesDataAction } from './stakeholdersMatching';

export const initialState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize: (
      state: Draft<IAuthState>,
      { payload }: { payload: { isAuthenticated: boolean; user: Maybe<AuthUser> } }
    ) => {
      state.isAuthenticated = payload.isAuthenticated;
      state.isInitialized = true;
      state.user = payload.user;
    },
    login: (state: Draft<IAuthState>, { payload }: { payload: { user: AuthUser } }) => {
      state.isAuthenticated = true;
      state.user = payload.user;
    },
    logout: (state: Draft<IAuthState>) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { initialize, logout, login } = authSlice.actions;

export default authSlice.reducer;

export const handleInitializeAction =
  (isAuthenticated: boolean, user: Maybe<AuthUser>) => (dispatch: Dispatch) => {
    dispatch(initialize({ isAuthenticated: isAuthenticated, user: user }));
  };

export const handleLoginAction = (user: AuthUser) => (dispatch: Dispatch) => {
  dispatch(login({ user }));
};

export const handleLogoutAction = () => (dispatch: Dispatch) => {
  dispatch(logout());
  resetClientDataAction()(dispatch);
  resetLogsDataAction()(dispatch);
  resetMonitoringDataAction()(dispatch);
  resetNotificationStoreAction()(dispatch);
  resetMatchesDataAction()(dispatch);
  TokenService.clearDataFromToken();
  SessionStorageService.clear();
};
