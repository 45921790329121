import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (expTime?: number) => {
  if (!expTime) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  return expTime > currentTime;
};

const setSession = (token: string | null) => {
  if (token) {
    localStorage.setItem('token', token);
    //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(token);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
