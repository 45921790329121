import React, { forwardRef } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: React.ReactNode;
  meta?: React.ReactNode;
  title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <>
        <title>{`${title} | KYP`}</title>
        {meta}
      </>
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

export default Page;
