import { AxiosError } from 'axios';
import { dispatch as storeDispatch } from '../../redux/store';
import { dispatchNotification } from '../../redux/slices/notification';

export interface ErrorMessage {
  message: string;
}
export default class ErrorService {
  public static async handleErrorMessage(e: AxiosError) {
    // eslint-disable-next-line no-console
    console.log(e, 'error');
    if (e.code === '401' || !e.response) return;

    const errorResponse = e.response?.data as { message?: string };

    await dispatchNotification({ open: true, message: errorResponse?.message || 'Server Error' })(
      storeDispatch
    );
  }
}
