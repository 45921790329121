import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { UserRole } from '../redux/auth/auth.type';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: UserRole[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { user } = useAuth();
  const roles = user?.rls || [];

  if (!roles.every((currentValue) => accessibleRoles.includes(currentValue))) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
