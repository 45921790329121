import React from 'react';
import Page from '../../../components/Page';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from '../../../routes/path';
import { Layout } from './style';
import { useTheme } from '@mui/material/styles';
import UpdatePasswordForm from "../../../sections/auth/update-password";

const UpdatePassword = () => {
  const theme = useTheme();
  return (
    <Page title="Update Password" sx={{ height: 1, background: 'rgb(40, 39, 39)' }}>
      <Layout spacing={theme.spacing(12, 0)}>

        <Container>
          <Box sx={{ maxWidth: 350, mx: 'auto' }}>
            <Typography variant="h6" paragraph align={'center'} color={theme.palette.common.white}>
              Create new password
            </Typography>
            <div>
              <UpdatePasswordForm />
            </div>
            <Button
              fullWidth
              size="large"
              component={RouterLink}
              to={PATH_AUTH.login}
              sx={{ mt: 1 }}
            >
              Back
            </Button>
          </Box>
        </Container>
      </Layout>
    </Page>
  );
};

export default UpdatePassword;
