// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/path';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking'),
  analytics: getIcon('ic_analytics'),
  settings: getIcon('ic_setting'),
  logs: getIcon('ic_logs'),
  monitoringClient: getIcon('ic_monitoring'),
  monitoringCompany: getIcon('ic_monitoring_analytics'),
  reload: getIcon('ic_reload'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      { title: 'Clients', path: `${PATH_DASHBOARD.general.clients}`, icon: ICONS.dashboard },
      { title: 'Users', path: `${PATH_DASHBOARD.general.users}`, icon: ICONS.user },
      {
        title: 'Settings',
        icon: ICONS.settings,
        path: `${PATH_DASHBOARD.general.settings.root}`,
        children: [
          {
            title: 'Roles',
            icon: ICONS.settings,
            path: `${PATH_DASHBOARD.general.settings.restrictions}`,
          },
        ],
      },
    ],
  },
  {
    subheader: 'MONITORING DASHBOARD',
    items: [
      {
        title: 'Client Statistics',
        path: `${PATH_DASHBOARD.monitoring.clientStatistics}`,
        icon: ICONS.monitoringClient,
      },
      {
        title: 'Client Companies',
        path: `${PATH_DASHBOARD.monitoring.clientCompanies}`,
        icon: ICONS.monitoringCompany,
      },

      {
        title: 'Reload Company Data',
        path: `${PATH_DASHBOARD.monitoring.allCompaniesList}`,
        icon: ICONS.reload,
      },
    ],
  },
  {
    subheader: 'LOGS',
    items: [
      {
        title: 'Related Info',
        path: `${PATH_DASHBOARD.general.logs.relatedInfo}`,
        icon: ICONS.logs,
      },
    ],
  },

  {
    subheader: 'DATA ANALYSIS',
    items: [
      {
        title: 'Stakeholders Matching',
        path: `${PATH_DASHBOARD.dataAnalysis.stakeholdersMatching}`,
        icon: ICONS.analytics,
      },
    ],
  },
];

export default navConfig;
