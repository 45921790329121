// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextFieldProps } from '@mui/material';
import StyledTextField from '../StyledTextField';
import styles from './RHFControls.module.scss';
import React from 'react';
// ----------------------------------------------------------------------

type InputModeType = 'white' | 'dark';

interface IProps {
  name: string;
  mode?: InputModeType;
}

export default function RHFTextField({ name, mode, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  const themeMode = React.useMemo(() => (mode ? `${mode}Mode` : undefined), [mode]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          fullWidth
          classes={{ root: themeMode ? styles[themeMode] : undefined }}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
