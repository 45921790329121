import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import Login from '../pages/auth/Login';
import AuthGuard from '../guards/AuthGuard';
import UpdatePassword from '../pages/auth/updatePassword';
import ResetPassword from '../pages/auth/resetPassword';
import { PATH_DASHBOARD } from './path';
import useAuth from '../hooks/useAuth';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { UserRole } from '../redux/auth/auth.type';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password/:token', element: <UpdatePassword /> },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={[UserRole.KYP]}>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/clients" replace />, index: true },
        { path: `${PATH_DASHBOARD.general.clients}`, element: <Clients /> },
        { path: `${PATH_DASHBOARD.general.inviteUser}/:clientId`, element: <InviteUser /> },
        {
          path: `${PATH_DASHBOARD.general.clientAccount}/:clientId`,
          element: <ClientAccountPage />,
        },
        { path: `${PATH_DASHBOARD.general.client}`, element: <CreateClient /> },
        { path: `${PATH_DASHBOARD.general.users}`, element: <Users /> },
        { path: `${PATH_DASHBOARD.general.logs.relatedInfo}`, element: <RelatedInfoLogs /> },
        { path: `${PATH_DASHBOARD.general.settings.restrictions}`, element: <Restrictions /> },
        {
          path: `${PATH_DASHBOARD.dataAnalysis.stakeholdersMatching}`,
          element: <StakeholdersMatching />,
        },
        {
          path: `${PATH_DASHBOARD.monitoring.clientStatistics}`,
          element: <ClientStatistics />,
        },
        {
          path: `${PATH_DASHBOARD.monitoring.clientCompanies}`,
          element: <ClientCompanies />,
        },
        {
          path: `${PATH_DASHBOARD.monitoring.allCompaniesList}`,
          element: <ReloadServicePage />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const Clients = Loadable(lazy(() => import('../pages/dashboard/clients/clientsPage/ClientsPage')));
const Users = Loadable(lazy(() => import('../pages/dashboard/users/usersPage/UsersPage')));
const RelatedInfoLogs = Loadable(
  lazy(() => import('../pages/dashboard/userLogs/RelatedInfoLogsPage/RelatedInfoLogsPage'))
);
const CreateClient = Loadable(
  lazy(() => import('../pages/dashboard/clients/clientsPage/createClientPage/CreateClientPage'))
);
const InviteUser = Loadable(
  lazy(() => import('../pages/dashboard/clients/clientsPage/inviteUserPage/InviteUserPage'))
);

const ClientAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/clients/clientsPage/cleintAccountPage/ClientAccountPage'))
);
const StakeholdersMatching = Loadable(
  lazy(() => import('../pages/dashboard/stakeholderMatching/StakeholdersMatchingPage'))
);
const ClientStatistics = Loadable(
  lazy(() => import('../pages/dashboard/monitoring/ClientStatistics'))
);
const ClientCompanies = Loadable(
  lazy(() => import('../pages/dashboard/monitoring/ClientCompanies'))
);

const ReloadServicePage = Loadable(
  lazy(() => import('../pages/dashboard/monitoring/ReloadServicePage'))
);

const Restrictions = Loadable(lazy(() => import('../pages/dashboard/restrictions/Restrictions')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
