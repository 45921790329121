import { SettingsValueProps } from './components/settings/type';

export const HOST_API = process.env.REACT_APP_API_URL || '';

const apiEntry = process.env.NODE_ENV === 'production' ? 'api' : 'api';
const loginServiceApiEntry = process.env.NODE_ENV === 'production' ? 'api' : 'api';

export const API = {
  login: `${loginServiceApiEntry}/login`,
  logout: `${loginServiceApiEntry}/logout`,
  refresh: `${loginServiceApiEntry}/refresh`,

  getClients: `${apiEntry}/admin/clients`,
  createClient: `${apiEntry}/admin/client`,
  inviteUser: `${apiEntry}/clients/invite`,

  getUsers: `${apiEntry}/admin/users`,

  getMatches: `${apiEntry}/admin/individuals/company`,
  getCompanies: `${apiEntry}/admin/individuals/company/all`,
  updateMatches: `${apiEntry}/admin/individuals/reviews`,

  relatedInfoLogs: `${apiEntry}/admin/user/clients`,

  clientsStatistic: `${apiEntry}/admin/stats/users`,
  clientsCompanies: `${apiEntry}/admin/stats/companies`,

  allCompaniesList: `${apiEntry}/admin/companies/all`,
  reloadService: `${apiEntry}/admin/update`,

  downloadPdf: `${apiEntry}/admin/pdf/client`,
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
