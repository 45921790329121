import { createSlice, Draft } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import RestService from '../../services/RestService';
import { AxiosResponse } from 'axios';
import ErrorService from '../../services/ErrorService';
import {
  ICompaniesResponse,
  IMatchesResponse,
  IStakeholdersMatching,
  IStakeholdersMatchingAll,
  IStakeholdersMatchingState,
} from '../stakeholdersMatching/stakeholdersMatching';
import { GetState } from '../store';
import { IMappedList } from '../../pages/dashboard/stakeholderMatching/SubmittedMatchingList/SubmittedMatchingList';

const initialState: IStakeholdersMatchingState = {
  loading: false,
  matchesIsLoading: null,
  matches: null,
  companies: null,
  companyOptions: null,
};

const stakeholdersMatchingSlice = createSlice({
  name: 'stakeholdersMatching',
  initialState,
  reducers: {
    loading: (state: Draft<IStakeholdersMatchingState>, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    matchesIsLoading: (
      state: Draft<IStakeholdersMatchingState>,
      { payload }: { payload: Maybe<{ [key: string]: boolean }> }
    ) => {
      state.matchesIsLoading = payload;
    },
    getMatches: (
      state: Draft<IStakeholdersMatchingState>,
      { payload }: { payload: Maybe<{ [key: string]: IStakeholdersMatching[] }> }
    ) => {
      state.matches = payload;
    },
    getCompanies: (
      state: Draft<IStakeholdersMatchingState>,
      { payload }: { payload: Maybe<IStakeholdersMatchingAll[]> }
    ) => {
      state.companies = payload;
    },

    getCompanyOptions: (
      state: Draft<IStakeholdersMatchingState>,
      { payload }: { payload: Maybe<{ id: string; label: string }[]> }
    ) => {
      state.companyOptions = payload;
    },
    reset: (state) => Object.assign(state, initialState),
  },
});

export const { loading, matchesIsLoading, getMatches, getCompanies, getCompanyOptions, reset } =
  stakeholdersMatchingSlice.actions;

export default stakeholdersMatchingSlice.reducer;

export const getCompaniesAction = (companyId?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(loading(true));
    const response: AxiosResponse<ICompaniesResponse> = await RestService.getCompanies(companyId);
    dispatch(getCompanies(response.data.data));

    // set filter data
    if (!companyId) {
      const data = response.data.data
        .map(({ companyID, companyName }) => ({
          id: companyID,
          label: companyName,
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      const res = [
        // @ts-ignore
        ...data
          .reduce((a, c) => {
            a.set(c.id, c);
            return a;
          }, new Map())
          .values(),
      ];

      dispatch(getCompanyOptions(res));
    }
    dispatch(loading(false));
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
    dispatch(loading(false));
  }
};

export const getMatchesAction =
  (companyId: string) => async (dispatch: Dispatch, getState: GetState) => {
    try {
      const { matches } = getState().stakeholdersMatching;

      dispatch(matchesIsLoading({ [companyId]: true }));
      const response: AxiosResponse<IMatchesResponse> = await RestService.getMatches(companyId);

      let updatedMatches;

      if (matches) {
        if (matches[companyId]) {
          const stakeholdersIds = matches[companyId].map(
            (item: IStakeholdersMatching) => item.stakeholderID
          );
          const updatedWithOutCached = response.data.data.filter(
            (item) => !stakeholdersIds.includes(item.stakeholderID)
          );
          updatedMatches = {
            ...matches,
            [companyId]: matches[companyId].concat(updatedWithOutCached),
          };
        } else {
          updatedMatches = { ...matches, [companyId]: response.data.data };
        }
      } else {
        updatedMatches = { [companyId]: response.data.data };
      }

      dispatch(getMatches(updatedMatches));

      dispatch(matchesIsLoading(null));
    } catch (e) {
      await ErrorService.handleErrorMessage(e);
      dispatch(matchesIsLoading(null));
    }
  };

export const setMatchAction =
  (row: IStakeholdersMatching) => (dispatch: Dispatch, getState: GetState) => {
    const { matches } = getState().stakeholdersMatching;
    let updatedRows: Maybe<IStakeholdersMatching[]> = matches ? [...matches[row.companyID]] : null;
    if (matches && updatedRows) {
      const index = updatedRows.findIndex(
        (item: IStakeholdersMatching) => item.stakeholderID === row.stakeholderID
      );
      if (index || index === 0) {
        updatedRows.splice(index, 1, row);
      }

      const updatedMatches = { ...matches, [row.companyID]: updatedRows };
      dispatch(getMatches(updatedMatches));
    }
    // TODO handle error
  };

export const setCachedMatchesAction =
  (matches: { [key: string]: IStakeholdersMatching[] }) => (dispatch: Dispatch) => {
    dispatch(getMatches(matches));
  };

export const updateMatchesAction = (list: Partial<IMappedList>[]) => async () => {
  try {
    await RestService.updateMatches({ reviews: list });
  } catch (e) {
    await ErrorService.handleErrorMessage(e);
  }
};

export const resetMatchesDataAction = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
